<template lang="">
<div>
    <div class="headers">
            <span @click="goUp()"></span>
            <span>到达拆解厂，到场核验</span>
            <span @click="islogout">退出</span>
    </div>
    <div class="arrive">
        <p>车辆核验影像：（按照指定部位拍摄）</p>
        <div class="img_box">
            
              <div v-if="!isWechat">
               <van-uploader v-model="fileList_a" multiple :max-count="1" :after-read="afterRead_a" :before-delete="beforeDelete_a"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="top_top" class="vanImage" width="80"  height="80"  :src="top_top" @click="bigImg(top_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('top_top')" />
                </van-image>
                <span v-if="!top_top" class="uploaderImg" @click="WeChooseImage(1,'top_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            <span>头部+车顶</span>
              
        </div>
          <div class="img_box">
             <div v-if="!isWechat">
                <van-uploader v-model="fileList_b" multiple :max-count="1" :after-read="afterRead_b" :before-delete="beforeDelete_b"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="left_top" class="vanImage" width="80"  height="80"  :src="left_top" @click="bigImg(left_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('left_top')" />
                </van-image>
                <span v-if="!left_top" class="uploaderImg" @click="WeChooseImage(1,'left_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            
                <span>左侧+车顶</span>
        </div>
          <div class="img_box">
             <div v-if="!isWechat">
                <van-uploader v-model="fileList_c" multiple :max-count="1" :after-read="afterRead_c" :before-delete="beforeDelete_c"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="right_top" class="vanImage" width="80"  height="80"  :src="right_top" @click="bigImg(right_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('right_top')" />
                </van-image>
                <span v-if="!right_top" class="uploaderImg" @click="WeChooseImage(1,'right_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
             
                <span>右侧+车顶</span>
        </div>
          <div class="img_box">
            <div v-if="!isWechat">
               <van-uploader v-model="fileList_d" multiple :max-count="1" :after-read="afterRead_d" :before-delete="beforeDelete_d"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="button_top" class="vanImage" width="80"  height="80"  :src="button_top" @click="bigImg(button_top)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('button_top')" />
                </van-image>
                <span v-if="!button_top" class="uploaderImg" @click="WeChooseImage(1,'button_top')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
            
                <span>尾部+车顶</span>
        </div>
          <div class="img_box">
            <div v-if="!isWechat">
              <van-uploader v-model="fileList_e" multiple :max-count="1" :after-read="afterRead_e" :before-delete="beforeDelete_e"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="inside" class="vanImage" width="80"  height="80"  :src="inside" @click="bigImg(inside)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('inside')" />
                </van-image>
                <span v-if="!inside" class="uploaderImg" @click="WeChooseImage(1,'inside')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
           
                <span>机盖内部</span>
        </div>
          <div class="img_box">
              <div v-if="!isWechat">
              <van-uploader v-model="fileList_f" multiple :max-count="1" :after-read="afterRead_f" :before-delete="beforeDelete_f"/>
            </div>
            <div v-else style="text-align: center;">
                <van-image v-if="feature" class="vanImage" width="80"  height="80"  :src="feature" @click="bigImg(feature)" >
                    <van-icon class="iconX" name="close" @click="delImgHan('feature')" />
                </van-image>
                <span v-if="!feature" class="uploaderImg" @click="WeChooseImage(1,'feature')"> 
                    <van-icon class="iconz" name="plus" />
                </span>
            </div>
           
                <span>三元特写</span>
        </div>      
        <div class="submit" @click="submit()">提交并完成运输订单</div>

    </div>
</div>
   
</template>
<script>
import { Toast } from "vant";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
export default {
  data() {
    return {
      remark: "",
      fileList: [],
      vehicleImages: "",
      uploadImgBox: [],
      img_box: [],
      fileList_a: [],
      fileList_b: [],
      fileList_c: [],
      fileList_d: [],
      fileList_e: [],
      fileList_f: [],
      top_top: "", //头部加车顶
      left_top: "", //左侧加车顶
      right_top: "", //右侧加车顶
      button_top: "", //尾部加车顶
      inside: "", //机盖特写
      feature: "", //三元特写
       wximagelocalids:[],//判断上传张数
      toast:null,
      WXimg: [],
    };
  },
  created() {
    let data = encipherMent( JSON.stringify({id:this.$route.query.id}))
    let param = new URLSearchParams();
    param.append("value",data);
    this.$http
      .post("/index.php/dispatch_img_scene/find", param)
      .then((res) => {
          let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
        if (result.code == 0) {
          // let http = "http://192.168.1.50:8000/";
          let http = this.GLOBAL.BASE_URL;;
          if (result.data) {
            if (result.data.top_top.length > 0) {
              // alert("1"+result.data.licensePlate)
              // //console.log(result.data.licensePlate)
              //  //console.log(result.data.licensePlate.replace("_1",""))

              this.fileList_a = [
                {
                  url: http + result.data.top_top.replace("_1", ""),
                },
              ];
              this.top_top = result.data.top_top;
            }
            if (result.data.left_top.length > 0) {
              this.fileList_b = [
                {
                  url: http + result.data.left_top.replace("_1", ""),
                },
              ];
              this.left_top = result.data.left_top;
            }
            if (result.data.right_top.length > 0) {
              this.fileList_c = [
                {
                  url: http + result.data.right_top.replace("_1", ""),
                },
              ];
              this.right_top = result.data.right_top;
            }
            if (result.data.button_top.length > 0) {
              this.fileList_d = [
                {
                  url: http + result.data.button_top.replace("_1", ""),
                },
              ];
              this.button_top = result.data.button_top;
            }
            if (result.data.inside.length > 0) {
              this.fileList_e = [
                {
                  url: http + result.data.inside.replace("_1", ""),
                },
              ];
              this.inside = result.data.inside;
            }
            if (result.data.feature.length > 0) {
              this.fileList_f = [
                {
                  url: http + result.data.feature.replace("_1", ""),
                },
              ];
              this.feature = result.data.feature;
            }
          }
        }
      });
  },
   mounted () {
            this.wcConfig()
        },
  watch:{
      wximagelocalids:{
        handler(newValue, oldValue){
              if(newValue.length > 0 ){
            this.toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "上传中..."
            });
            this.wxUpload(newValue[0])
        }else{
            this.toast.clear();
        }
          },
        deep: true,
    }
  },
  methods: {
    //头部加车顶
    afterRead_a(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.top_top = res.data.data;
                    Toast.clear();

        }
      });
    },
    //左侧加车顶
    afterRead_b(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 200);
      params.append("h", 360);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.left_top = res.data.data;
                    Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //右侧加车顶
    afterRead_c(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.right_top = res.data.data;
                    Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //尾部加车顶
    afterRead_d(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.button_top = res.data.data;
                    Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //机盖特写
    afterRead_e(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          //console.log(res.data.data);
          this.inside = res.data.data;
                    Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    //三元特写
    afterRead_f(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      // //console.log(file)
      // 此时可以自行将文件上传至服务器
      let params = new FormData(); //创建form对象
      params.append("file", file.file);
      params.append("w", 400);
      params.append("h", 300);
      this.$http.post("/index.php/index/upload", params).then((res) => {
        if (res.data.code == 0) {
          
          //console.log(res.data.data);
          this.feature = res.data.data;
                    Toast.clear();

        }
        // //console.log(this.uploadPicList)
      });
    },
    beforeDelete_a() {
      //   //console.log(res)
      this.top_top = "";
      this.fileList_a = [];
    },
    beforeDelete_b() {
      this.left_top = "";
      this.fileList_b = [];
    },
    beforeDelete_c() {
      this.right_top = "";
      this.fileList_c = [];
    },
    beforeDelete_d() {
      this.button_top = "";
      this.fileList_d = [];
    },
    beforeDelete_e() {
      this.inside = "";
      this.fileList_e = [];
    },
    beforeDelete_f() {
      this.feature = "";
      this.fileList_f = [];
    },
    submit() {
      //console.log(this.vehicleImages);
      let obj = {
          id: this.$route.query.id,
          top_top: this.top_top,
          left_top: this.left_top,
          right_top: this.right_top,
          button_top: this.button_top,
          inside: this.inside,
          feature: this.feature,
        }
       let data = encipherMent( JSON.stringify(obj))
        let param = new URLSearchParams();
        param.append("value",data);
      this.$http
        .post("/index.php/dispatch_img_scene/add",param )
        .then((res) => {
            let result = {}
                        if(res.data.code == -1 || res.data.code == 9){
                            result = res.data
                        }else{
                           result = JSON.parse( encryptDecode(res.data))
                        }
          if (result.code == 0) {
            Toast.success(result.msg);
            this.$router.go('-1')
          } else {
            Toast.fail(result.msg);
          }
        });
    },
    goUp() {
      this.$router.go("-1");
    },
  },
};
</script>
<style lang="scss" scoped> 
.headers {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;

  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-image: url(../../assets/u39.png);
    background-size: 100%;
    margin-top: 8px;
  }
}
.arrive {
  width: 96%;
  margin: 0 auto;
  p {
    font-size: 0.38rem;
  }
  .img_box {
    height: 4rem;
    font-size: 0.38rem;
    width: 3rem;
    display: inline-block;
    span {
      display: inline-block;
      width: 3rem;
      text-align: center;
    }
    .van-uploader {
      height: 2.5rem;
      width: 2.5rem;
      margin-left: 0.3rem;
      margin-top: 0.3rem;
      // float: left;
      .van-uploader__wrapper {
        height: 100%;
        width: 100%;
        float: left;
        .van-uploader__upload {
          height: 100%;
          width: 100%;
        }
        .van-uploader__preview {
          height: 100%;
          width: 100%;
          .van-uploader__preview-image {
            height: 100%;
            width: 100%;
          }
        }
      }
       .van-uploader__preview-delete{
        height: 0.5rem;
        width: 0.5rem;
        .van-uploader__preview-delete-icon{
 height: 0.7rem;
        width: 0.5rem;
        }
      }
    }
  }

  .submit {
    width: 100%;
    height: 0.8rem;
    font-size: 0.38rem;
    line-height: 0.8rem;
    text-align: center;
    color: white;
    margin: 20px auto;
    background-color: rgba(0, 153, 255, 1);
    border-radius: 6px;
  }
}
    .uploaderImg{
        display: inline-block;
        width: 80px !important;
        height: 80px !important;
        border: 1px solid #eee;
        position: relative;
      
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>